import * as moment from 'moment'
import { merge, Observable, Subject } from 'rxjs'
import { switchMap } from 'rxjs/operators';
import { Filter } from '../interfaces/ticket.interface'
import { BaseEntity } from '../services/base.service';
import { saveAs as importedSaveAs } from "file-saver"
import { Ticket } from '../states/ticket';

// Export utils (common functions, constants and methods)
export const Utils = {
  flatArray: (array) => array.reduce(function (a, b) {
    return a.concat(b);
  }, []),
  getMonthOrDay: (number) => getMonthOrDay(number),
  getDate: (number) => getDate(number),
  parseObjectToQueryString: (filter: Object) => objectToQueryString(filter),
  parseFilterToQueryString: (filter: Filter) => filterToQueryString(filter),
  parseDateToString: (date: string | Date, withHour: boolean = true) => parseDateToString(date, withHour),
  parseStringDateToDateJsonFormat: (dateString: string) => parseStringDateToDateJsonFormat(dateString),
  getHtmlAvailableDays: (days) => `<strong>${days} días hábiles</strong>`,
  getStringQueryResult: (debouncedText$, inputFocus$, clicksWithClosedPopup$, dataArray$) => getStringQueryResult(debouncedText$, inputFocus$, clicksWithClosedPopup$, dataArray$),
  getAutocompleteDefaultFormater: (data: BaseEntity) => data.name,
  isNumeric: (str) => {
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(parseInt(str)) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
  },
  isDateLate: (counterDays: number) => isDateLate(counterDays),
  getDateWithHourFromString: (dateString: string) => getDateWithHourFromString(dateString),
  downloadByDataType: (filenName: string, data: any) => downloadByDataType(filenName, data),
  isInvalidForAction: (ticketStateId) => [
    3,
    19 // Temp state
  ].includes(ticketStateId),
  isEntityState: (ticketStateId) => ticketStateId == 4,
  isRadicatedState: (ticketStateId) => ticketStateId == 1,
  isRejectedState: (ticketStateId) => ticketStateId == 3,
  // Constants
  getLogByData: (state, userName, ticketData, logData) => getLogByData(state, userName, ticketData, logData),
  extraTicketStates: {
    TICKET_ANSWER: 1,
    MOANFUL_INFO_REQUEST: 2,
    TICKET_EXTENSION: 3,
    LOCAL_INFO_REQUEST: 4,
    APREMIO_REQUEST: 5,
    VOCERIA_CREATION: 6,
    PRONOUNCEMENT: 7,
    REJECTION: 8
  },
  ticketStates: {
    ATTONERY_ANSWER: 13,
    PRONOUNCEMENT: 16,
    PRONOUNCEMENT_APRPOVE: 19,
    REJECTION: 3,
    INITIAL: 1,
    ENTITY: 4,
    CHANGE_ENTITY: 5,
    LOCAL: 10,
    SPOKESPERSON: 14
  },
  answerableTicketStates: {
    ENTITY: 4,
    LOCAL: 10,
  },
  REPLICA_TYPE_STATE: 3,
  ENTITY_ROLE_ID: 4,
  LAWYER_ROLE_ID: 3,
  ROLES_CAN_SELECT_ENTITIES: [3, 4],
  IMAGE_FORMATS: ['jpg', 'jpeg', 'png'],
  RADICATOR: 'RADICADOR',
  ENTITY: 'ENTIDAD',
  LAWYER: 'ABOGADO',
  APPROVER: 'APROBADOR',
  MOANFUL: 'QUEJOSO',
  ADMINISTRATOR: 'ADMINISTRADOR',
  ROLES: [
    'RADICADOR',
    'ENTIDAD',
    'ABOGADO',
    'APROBADOR',
    'QUEJOSO',
    'ADMINISTRADOR'
  ],
  SELF_INCLUDED_ENTITY_ROLES: [
    'RADICADOR',
    'ABOGADO'
  ],
  DATA_RANGE_PICKER_LOCALE_FORMAT: {
    format: 'YYYY/MM/DD',
    displayFormat: 'YYYY/MM/DD',
    separator: ' - ',
    cancelLabel: 'Cancelar',
    applyLabel: 'Aplicar'
  },
  DATE_RANGES_FORMATS: {
    'Hoy': [moment(), moment()],
    'Ayer': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Últimos 7 Días': [moment().subtract(6, 'days'), moment()],
    'Últimos 30 Días': [moment().subtract(29, 'days'), moment()],
    'Este Mes': [moment().startOf('month'), moment().endOf('month')],
    'Mes Pasado': [
      moment()
        .subtract(1, 'month')
        .startOf('month'),
      moment()
        .subtract(1, 'month')
        .endOf('month')
    ]
  },
}


// Core functions -------------------------------------------

function getLogByData(state, userName, ticketData: Ticket, logData) {

  switch (state) {
    case 1:
      return {
        state: 'Ingresado',
        messageHeader: 'Registra una petición',
        messageContent: `El usuario <b>${userName}</b> realiza una solicitud respecto a su inconformidad`
      };
    case 4:
      return {
        state: 'Entidad',
        messageHeader: 'Revisa y radica petición',
        messageContent: `El usuario <b>${userName}</b> remite la solicitud a la entidad <b>${ticketData.entityName}</b>`
      }
    case 3:
      return {
        state: 'Rechazado',
        messageHeader: 'Rechaza la petición',
        messageContent: `El usuario <b>${userName}</b> rechaza la solicitud hecha por el cliente`
      }
    case 5:
      return {
        state: 'Cambio Entidad',
        messageHeader: 'Cambio de Entidad',
        messageContent: `La entidad <b>${userName}</b> cambia la entidad de la solicitud a <b>${ticketData.entityName}</b>`
      }
    case 10:
      return {
        state: 'Respuesta Entidad',
        messageHeader: 'Respuesta de la Entidad',
        messageContent: `La entidad <b>${userName}</b> ha radicado una respuesta`
      }
    case 11:
      return {
        state: 'Respuesta Defensoria',
        messageHeader: 'Respuesta de la Defensoria',
        messageContent: `El usuario <b>${userName}</b> ha radicado una respuesta`
      }
    case 12:
      return {
        state: 'Asignación de abogado',
        messageHeader: 'Asignación de abogado',
        messageContent: `Petición asignada al abogado <b>${userName}</b>`
      }
    case 16:
      return {
        state: 'Creacion Pronunciamento',
        messageHeader: 'Creacion de pronunciamento',
        messageContent: `El abogado <b>${userName}</b> ha radicado un pronunciamento`
      }
    case 15:
      return {
        state: 'Defensoria-Revision',
        messageHeader: 'Revision de la defensoria',
        messageContent: `El abogado <b>${userName}</b> esta en proceso de revision de la solicitud`
      }
    case 17:
      return {
        state: 'Respuesta Solicitud',
        messageHeader: 'Respuesta de solicitud',
        messageContent: `El abogado <b>${userName}</b> ha radicado una respuesta.`
      }
    case 8:
      return {
        state: 'Solicitud Extension',
        messageHeader: 'Solicitud de extension',
        messageContent: `La entidad <b>${userName}</b> ha solicitado una <b>extension</b>`
      }
    case 19:
      return {
        state: 'Aprobado',
        messageHeader: 'Aprobacion de solicutud',
        messageContent: `El usuario <b>${userName}</b> realiza la aprobacion de la solicitud`
      }
    case 22:
        return {
          state: 'Reasignación de abogado',
          messageHeader: 'Reasignación de abogado',
          messageContent: `Petición reasignada al abogado <b>${userName}</b>`
        }
    case 23:
      return {
        state: 'Cambio de producto/motivo',
        messageHeader: 'Cambio de producto/motivo',
        messageContent: `El usuario <b>${userName}</b> realiza un <b>Cambio de producto y/o motivo</b>`
      }
    default:
      return {
        state: logData.detalle,
        messageHeader: state,
        messageContent: `El usuario <b>${userName}</b> realiza <b>${logData.stateTicket.detalle}</b>`
      }
  }
}

function downloadByDataType(fileName: string, data: any) {
  var blob = new Blob([data.body], { type: data.body.type.toString() });
  importedSaveAs(blob, fileName)
}

function getDateWithHourFromString(dateString: string) {
  const dateD = moment(dateString);
  const dateString_ = dateD.toLocaleString();
  let dateOperator: moment.Moment = null;
  if (dateString_.includes('GMT-')) {
    dateOperator = dateD.subtract(dateD.toDate().getTimezoneOffset(), 'm')
  } else if (dateString_.includes('GMT+')) {
    dateOperator = dateD.add(dateD.toDate().getTimezoneOffset(), 'm')
  } else {
    dateOperator = dateD
  }
  const separatedDate = dateOperator.format('YYYY-MM-DDThh:mm A').split('T');
  return {
    date: separatedDate[0],
    hour: separatedDate[1]
  }
}

function isDateLate(counterDays: number) {
  if (counterDays > 5) { return 1 }
  if (counterDays <= 2) { return 3 }
  return 2;
}

function getStringQueryResult(debouncedText$: Subject<String>, inputFocus$, clicksWithClosedPopup$, dataArray$) {
  return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
    switchMap(term => dataArray$.map(dataArray => {
      return dataArray.map(it => it.name).filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10)
    }))
  );
}

function getMonthOrDay(number) {
  if (parseInt(number, 10) >= 10) { return number };
  return `0${number}`
}

function getDate(date) {
  if(date == null) return new Date().toJSON().slice(0, 10);
  return `${date.year}-${getMonthOrDay(date.month)}-${getMonthOrDay(date.day)}`
}

function parseDateToString(date: string | Date, withHour: boolean = true) {
  const format = `YYYY-MM-DD${withHour ? ' h:mm:ss A' : ''}`;
  const newFormat = moment(date).format(format);
  return newFormat;
}

function parseStringDateToDateJsonFormat(dateString?: string) {
  if (typeof dateString === 'undefined') { return null }
  const fDate = dateString.split('-')
  return {
    year: parseInt(fDate[0], 10),
    month: parseInt(fDate[1], 10),
    day: parseInt(fDate[2], 10)
  }
}

function objectToQueryString(filter: Object): string {
  let clearForm = Object.keys(filter)
        .filter((k) => filter[k] != null)
        .reduce((a, k) => ({ ...a, [k]: filter[k] }), {});

    return new URLSearchParams(clearForm).toString();
}

function filterToQueryString(filter: Filter): string {
  const notNullFilters = removeEmptyJsonFields<Filter>(filter)
  const totalNotNullFilters = Object.keys(notNullFilters).length - 1;
  let queryString = '?';
  Object.keys(notNullFilters).forEach((k, idx) => {
    queryString += `${k}=${notNullFilters[k]}`
    if (idx < (totalNotNullFilters)) { queryString += '&' }
  })
  return queryString;
}

export function removeEmptyJsonFields<T>(obj) {
  Object.keys(obj).forEach(k => {
    if (obj[k] === null || obj[k] === '') { delete obj[k] }
    return (obj[k] && typeof obj[k] === 'object') && removeEmptyJsonFields<T>(obj[k]) ||
      (!obj[k] && obj[k] !== undefined) ||
      (k === 'page' && typeof obj[k] !== 'number')
      && delete obj[k]
  }

  );
  return obj as T;
};
